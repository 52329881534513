import React from "react";
import { currencyWithoutDecimals } from "../currency";

export default class Mini extends React.Component {
  getTotalPrice(lineItems) {
    let total = 0;
    lineItems.forEach((lineItem) => (total += lineItem.subtotal()));
    return total;
  }
  render() {
    return (
      <div>
        {currencyWithoutDecimals(this.getTotalPrice(this.props.lineItems))}
      </div>
    );
  }
}
