import React from "react";
import { currencyWithoutDecimals } from "../currency";

export default class LineItem extends React.Component {
  render() {
    const {
      lineItem,
      addonChangeHandler,
      quantityChangeHandler,
      notesChangeHandler,
    } = this.props;

    const productID = lineItem.productID;

    const addonNodes = Object.entries(
      lineItem.standardProduct && lineItem.standardProduct.addons
        ? lineItem.standardProduct.addons
        : {},
    ).map(([id, addon]) => {
      const enabled = lineItem.addonsEnabled
        ? lineItem.addonsEnabled[id]
        : false;
      return (
        <label key={id} className="flex items-center space-x-3">
          <input
            type="checkbox"
            checked={enabled ? "checked" : ""}
            onChange={(event) =>
              addonChangeHandler(productID, id, event.target.checked)
            }
            className="w-6 h-6 mb-2 border border-gray-300 rounded-md appearance-none form-tick checked:bg-blue-600 checked:border-transparent focus:outline-none print:w-4 print:h-4 print:mb-1 print:text-xs"
          />
          <span>
            add {addon.title} (+ {currencyWithoutDecimals(addon.price)} each)
          </span>
        </label>
      );
    });

    let disclaimers = [];
    if (lineItem.delivery) {
      disclaimers.push(
        <div key="delivery" className="text-gray-600 print:text-xs">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="gray"
            className="inline-block w-4 h-4 align-top print:h-3 print:w-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>{" "}
          This product requires delivery and on-site installation
        </div>,
      );
    }

    if (lineItem.leadTimeWeeks) {
      disclaimers.push(
        <div key="leadTime" className="italic text-gray-600 print:text-xs">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="gray"
            className="inline-block w-4 h-4 align-top print:h-3 print:w-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>{" "} */}
          This product has a lead time of {lineItem.leadTimeWeeks} week
          {lineItem.leadTimeWeeks > 1 ? "s" : ""}
        </div>,
      );
    }

    let customizations = [];
    if (lineItem.customizations) {
      customizations = Object.entries(lineItem.customizations).map(
        ([key, value]) => {
          return (
            <div key={key} className="text-gray-700 print:text-xs">
              - {key}: {value}
            </div>
          );
        },
      );
    }

    return (
      <React.Fragment>
        <div className="grid grid-cols-12 gap-0 mt-12 print:mt-6">
          <div className="col-span-2 pr-2 sm:col-span-1 sm:row-span-2 self-top print:col-span-1 print:row-span-2">
            {lineItem.img ? <img src={lineItem.img} alt="" /> : null}
          </div>
          <div className="self-end col-span-10 col-start-1 font-bold border-b border-black sm:col-span-6 print:col-span-6 print:text-sm sm:pb-1">
            {lineItem.title}
            {lineItem.description ? (
              <div className="text-sm text-gray-700">
                {lineItem.description}
              </div>
            ) : null}
          </div>
          <div className="self-end col-span-2 font-bold text-right border-b border-black sm:col-span-1 print:col-span-1 print:text-sm sm:pb-1">
            {currencyWithoutDecimals(lineItem.priceWithAddons())}
          </div>
          <div className="self-end col-span-6 pb-1 mt-1 text-left border-black sm:col-span-2 sm:border-b sm:text-center print:col-span-2 print:border-b print:text-center print:text-sm">
            <input
              className="px-2 py-0 border-gray-200 w-14"
              type="number"
              step="1"
              value={lineItem.quantity}
              min="1"
              onChange={(event) =>
                quantityChangeHandler(productID, event.target.value)
              }
            />
            <button
              type="button"
              onClick={(event) => quantityChangeHandler(productID, 0)}
              className="ml-1 text-xs"
            >
              remove
            </button>
          </div>
          <div className="col-span-6 pt-1 font-bold text-right border-black sm:col-span-2 self-top sm:border-b sm:self-end print:col-span-2 print:border-b print:self-end print:text-sm sm:pb-1">
            <span className="mr-1 text-xs font-light sm:hidden">subtotal</span>
            {currencyWithoutDecimals(lineItem.subtotal())}
          </div>

          <div className="col-span-12 pt-2 space-y-1 text-sm sm:col-span-7 sm:col-start-2 print:col-span-7 print:col-start-2 print:text-xs">
            {disclaimers}
            {addonNodes}
            {customizations}
            <textarea
              onChange={(event) =>
                notesChangeHandler(productID, event.target.value)
              }
              placeholder="Add any notes about this item here"
              className="w-full mt-2 text-sm border border-gray-300 rounded print:text-xs"
              value={lineItem.notes}
            ></textarea>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
