import { doc } from 'prettier';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { add } from 'three/examples/jsm/nodes/Nodes.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
// import obj loader
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
// import gltf loader
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils.js';

export default function render(element) {
    // Get size of container
    let renderContainer = document.getElementById('render-container').getBoundingClientRect();

    // Set up scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, renderContainer.width / renderContainer.width, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(renderContainer.width, renderContainer.width); // Square aspect ratio based on width
    renderer.setClearColor(0x1f1e1c, 0);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Choose a tone mapping method
    renderer.toneMappingExposure = 1; // Adjust exposure
    // renderer.physicallyCorrectLights = true; // Enable physical lighting
    // renderer.outputEncoding = THREE.sRGBEncoding;
    // Enable shadow rendering
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: for softer shadows

    element.appendChild(renderer.domElement);

    const canvas = renderer.domElement;

    // Add Orbit Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Set the point around which the camera orbits
    const target = new THREE.Vector3(0, 24, 0); // Example target
    controls.target.copy(target);
    controls.update();

    // Prevent camera from going below ground
    const degrees = 80;
    const radians = degrees * Math.PI / 180;
    controls.maxPolarAngle = radians;

    // Add HDRI environment map
    const hdrEquirect = new RGBELoader().load(
        "/assets/img/threejs/hdr/rostock_laage_airport_1k.hdr",
        () => {
            hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
        }
    );

    // Create wood-like material
    const woodTexture = new THREE.TextureLoader().load('/assets/img/threejs/materials/cherry.jpg');
    const woodMaterial = new THREE.MeshPhysicalMaterial({
        map: woodTexture,
        clearcoat: 1,
    });
    woodTexture.colorSpace = THREE.SRGBColorSpace;

    const sampleGraphicsTexture32x78x1 = new THREE.TextureLoader().load('/assets/img/threejs/materials/sample-graphics/Upland-sample-32x78-111.jpg');
    sampleGraphicsTexture32x78x1.colorSpace = THREE.SRGBColorSpace;
    const sampleGraphicsTexture32x78x2 = new THREE.TextureLoader().load('/assets/img/threejs/materials/sample-graphics/Upland-sample-32x78-2.jpg');
    sampleGraphicsTexture32x78x2.colorSpace = THREE.SRGBColorSpace;
    const sampleGraphicsTexture32x78x3 = new THREE.TextureLoader().load('/assets/img/threejs/materials/sample-graphics/Upland-sample-32x78-3.jpg');
    sampleGraphicsTexture32x78x3.colorSpace = THREE.SRGBColorSpace;
    const sampleGraphicsTexture32x78x4 = new THREE.TextureLoader().load('/assets/img/threejs/materials/sample-graphics/Upland-sample-32x78-4.jpg');
    sampleGraphicsTexture32x78x4.colorSpace = THREE.SRGBColorSpace;
    const sampleGraphicsTexture32x78x5 = new THREE.TextureLoader().load('/assets/img/threejs/materials/sample-graphics/Upland-sample-32x78-5.jpg');
    sampleGraphicsTexture32x78x5.colorSpace = THREE.SRGBColorSpace;
    const sampleGraphicsTexture32x78x6 = new THREE.TextureLoader().load('/assets/img/threejs/materials/sample-graphics/Upland-sample-32x78-6.jpg');
    sampleGraphicsTexture32x78x6.colorSpace = THREE.SRGBColorSpace;
    const sampleGraphicsMaterial32x78x1 = new THREE.MeshPhysicalMaterial({ map: sampleGraphicsTexture32x78x1, opacity: 1, transparent: false, clearcoat: 1 });
    const sampleGraphicsMaterial32x78x2 = new THREE.MeshPhysicalMaterial({ map: sampleGraphicsTexture32x78x2, opacity: 1, transparent: false, clearcoat: 1});
    const sampleGraphicsMaterial32x78x3 = new THREE.MeshPhysicalMaterial({ map: sampleGraphicsTexture32x78x3, opacity: 1, transparent: false, clearcoat: 1});
    const sampleGraphicsMaterial32x78x4 = new THREE.MeshPhysicalMaterial({ map: sampleGraphicsTexture32x78x4, opacity: 1, transparent: false, clearcoat: 1});
    const sampleGraphicsMaterial32x78x5 = new THREE.MeshPhysicalMaterial({ map: sampleGraphicsTexture32x78x5, opacity: 1, transparent: false, clearcoat: 1});
    const sampleGraphicsMaterial32x78x6 = new THREE.MeshPhysicalMaterial({ map: sampleGraphicsTexture32x78x6, opacity: 1, transparent: false, clearcoat: 1});
    
    

    const paintMaterial = new THREE.MeshPhysicalMaterial({ color: '#606E7B' });
    const tricornBlackMaterial = new THREE.MeshPhysicalMaterial({ color: '#2F2F30' }); // SW 6258
    const mindfulGrayMaterial = new THREE.MeshPhysicalMaterial({ color: '#BCB7AD' }); // SW 7016

    // Create glass / acrylic material
    const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xa8ccd7,
        metalness: 0,
        roughness: 0.1,
        transmission: .9, // transparency
        opacity: 0.3,
        transparent: true,
        reflectivity: 0.9,
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        envMap: hdrEquirect,
    });

    // Function to create a striped texture with 45-degree transparency
    function createStripedTexture() {
        const size = 256; // Size of the texture
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const context = canvas.getContext('2d');

        // Draw striped pattern
        context.fillStyle = 'rgba(0, 0, 0, 0)'; // Transparent background
        context.fillRect(0, 0, size, size);

        const stripeWidth = 20;
        context.fillStyle = 'rgba(255, 255, 255, 0.5)'; // White stripes with 50% opacity
        // context.fillStyle = 'rgba(255, 0, 0, 0.15)'; 

        // Draw diagonal stripes
        for (let i = -size; i < size; i += stripeWidth * 2) {
            context.beginPath();
            context.moveTo(i, 0);
            context.lineTo(i + stripeWidth, 0);
            context.lineTo(i + size + stripeWidth, size);
            context.lineTo(i + size, size);
            context.closePath();
            context.fill();
        }

        const texture = new THREE.CanvasTexture(canvas);
        return texture;
    }

    // Create striped texture
    const stripedTexture = createStripedTexture();

    // Create material with the striped texture
    const stripedMaterial = new THREE.MeshBasicMaterial({
        map: stripedTexture,
        transparent: true // Enable transparency
    });

    // Function to create a texture with text
    function createTextTexture(text) {
        const size = 512; // Size of the texture
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const context = canvas.getContext('2d');

        // Background color
        context.fillStyle = 'rgba(255, 255, 255, 1)'; // White background
        context.fillRect(0, 0, size, size);

        // Text settings
        context.font = 'Bold 40px Arial';
        context.fillStyle = 'rgba(0, 0, 0, 1)'; // Black text
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        // Draw the text
        context.fillText(text, size / 2, size / 2);

        // Create texture
        const texture = new THREE.CanvasTexture(canvas);
        return texture;
    }

    // Create text texture
    const textTexture = createTextTexture('Museum Exhibit Panel');

    // Create material with the text texture
    const textMaterial = new THREE.MeshBasicMaterial({
        map: textTexture,
        transparent: false // Set to true if you want transparency
    });



    // Create Zig Zag base
    const radius = .5;
    const height = 1;
    const roundedEnd = new THREE.CylinderGeometry(radius, radius, height, 32, 1, false, 0, Math.PI);
    const roundedEnd1 = new THREE.Mesh(roundedEnd, tricornBlackMaterial);
    const roundedEnd2 = new THREE.Mesh(roundedEnd, tricornBlackMaterial);
    const straight = new THREE.BoxGeometry(1, 1, 1);
    const straight1 = new THREE.Mesh(straight, tricornBlackMaterial);
    
    scene.add(roundedEnd1);
    scene.add(roundedEnd2);
    scene.add(straight1);



    // Create Graphic Panels
    const graphicPanel1 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), mindfulGrayMaterial);
    const printedPanelFront = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), sampleGraphicsMaterial32x78x1);
    const printedPanelBack = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), sampleGraphicsMaterial32x78x4);

    scene.add(graphicPanel1);
    scene.add(printedPanelFront);
    scene.add(printedPanelBack);

    // Enable shadow casting
    graphicPanel1.castShadow = true;
    roundedEnd1.castShadow = true;
    roundedEnd2.castShadow = true;
    straight1.castShadow = true;


    // make a black powder coat metal material
    const blackMetalMaterial = new THREE.MeshPhysicalMaterial({
        color: 0x222222,
        metalness: 1,
        roughness: 0.1,
    });
    // load the top clamp
    let topClamp, topClamp2, topClamp3;
    const loader = new OBJLoader();
    loader.load('/assets/img/threejs/models/ZigZagTopClamp.obj', (object) => {
        topClamp = object;
        // apply the black metal material
        topClamp.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = blackMetalMaterial;
            }
        }, true);

        // set initial position for 2 panels
        topClamp.position.set(5, 78 - 5 / 8, .375);
        topClamp.rotation.x = -Math.PI / 2; // get to correct orientation
        topClamp.rotation.z = Math.PI / 2;
        scene.add(topClamp);

        topClamp2 = topClamp.clone();
        topClamp2.position.set(32-3.5, 78 - 5 / 8, -.375);
        topClamp2.rotation.x = -Math.PI / 2; // get to correct orientation
        topClamp2.rotation.z = -Math.PI / 2;
        scene.add(topClamp2);
        topClamp2.visible = false;

        topClamp3 = topClamp.clone();
        topClamp3.position.set(55, 78 - 5 / 8, .375);
        topClamp3.rotation.x = -Math.PI / 2; // get to correct orientation
        topClamp3.rotation.z = -Math.PI / 2;
        scene.add(topClamp3);
        topClamp3.visible = false;
    });




    // Add lighting ----------------------------------------------
    const light = new THREE.DirectionalLight(0xffffff, 3);
    light.position.set(30, 150, 40);
    // Enable shadow casting for light
    light.castShadow = true;
    // Adjust shadow camera frustum for better shadow quality
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 200;
    light.shadow.camera.left = -200;
    light.shadow.camera.right = 200;
    light.shadow.camera.top = 200;
    light.shadow.camera.bottom = -200;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    // Create a target for the directional light
    const lightTarget = new THREE.Object3D();
    lightTarget.position.set(0, 60, 0); // Set the target position
    scene.add(lightTarget);

    // Set the target of the directional light
    light.target = lightTarget;

    // Add light helpers
    // const directionalLightHelper = new THREE.DirectionalLightHelper(light, 3);
    // scene.add(directionalLightHelper);

    // Add ambient light to soften shadows
    const ambientLight = new THREE.AmbientLight(0xffffff, 4);
    scene.add(ambientLight);

    // const pointLight = new THREE.PointLight(0xffffff, 2);
    // pointLight.position.set(-80, 90, 30);
    // scene.add(pointLight);

    // // Add point light helper
    // const pointLightHelper = new THREE.PointLightHelper(pointLight, 3);
    // scene.add(pointLightHelper);


    // Add a ground plane to receive shadows
    const groundGeometry = new THREE.PlaneGeometry(200, 200);
    const groundMaterial = new THREE.MeshPhysicalMaterial({ color: 0xeeeeee });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.01; // Slightly below the pedestals
    ground.receiveShadow = true;
    scene.add(ground);


    // Position camera
    camera.position.set(-51, 40, 73); // looks nice for this scene
    controls.update();

    // Render loop
    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    }
    animate();

    // Handle window resizing
    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize() {
        renderContainer = document.getElementById('render-container').getBoundingClientRect();
        camera.aspect = renderContainer.width / renderContainer.width;
        camera.updateProjectionMatrix();
        renderer.setSize(renderContainer.width, renderContainer.width);
    }

    // Array to keep track of cloned groups
    let clonedGroups = [];

    // Function to clone a group and manage previous clones
    function cloneAndManageGroup(group, numClones) {
        // Remove previous clones from the scene
        clonedGroups.forEach(clone => {
            scene.remove(clone);
        });

        // Clear the array
        clonedGroups = [];

        // Create the specified number of clones
        for (let i = 0; i < numClones; i++) {
            // Clone the group
            const clonedGroup = group.clone(true);

            // Position the cloned group
            clonedGroup.position.set(10 * (i + 1), 0, 10 * (i + 1)); // Offset each clone to differentiate

            // Add the cloned group to the scene
            scene.add(clonedGroup);

            // Store the cloned group reference
            clonedGroups.push(clonedGroup);
        }

        // Return the array of cloned groups
        return clonedGroups;
    }

    




    const models = [
        { path: '/assets/img/threejs/models/Woman.glb', position: { x: 30, y: 0, z: -40 }, scale: { x: 37, y: 37, z: 37 } },
        { path: '/assets/img/threejs/models/Woman.glb', position: { x: 30, y: 0, z: -40 }, scale: { x: 0, y: 0, z: 0 } },
    ];


    // load some people into the model for scale (and for fun!)
    let currentPerson;
    function loadRandomModel() {
        if (currentPerson) {
            scene.remove(currentPerson);
        }

        const randomIndex = Math.floor(Math.random() * models.length);
        const selectedModel = models[randomIndex];

        const gltfLoader = new GLTFLoader();
        gltfLoader.load(selectedModel.path, function (gltf) {
            currentPerson = gltf.scene;
            currentPerson.position.set(selectedModel.position.x, selectedModel.position.y, selectedModel.position.z);
            currentPerson.scale.set(selectedModel.scale.x, selectedModel.scale.y, selectedModel.scale.z);
            scene.add(currentPerson);

            // Enable shadows for all meshes in the model
            currentPerson.traverse(function (node) {
                if (node.isMesh) {
                    node.castShadow = true;
                    node.receiveShadow = true;
                }
            });

        }, undefined, function (error) {
            console.error(error);
        });
    }

    loadRandomModel();




    function zigZagBuilder() {
        let panelGap = 1.5;
        let panelWidth = Number(document.getElementById('width_input').value);
        let panelHeight = Number(document.getElementById('height_input').value);
        let numPanels = Number(document.getElementById('num_panels_input').value);
        let baseOnly = document.getElementById('base-only').checked;
        let baseAndGraphicBacker = document.getElementById('base-with-graphics-backer').checked;
        let baseAndGraphicBackerAndPrintedPanel = document.getElementById('base-with-graphics-backer-and-production').checked;
        let baseHeight = 2.25; // three pieces of 3/4" baltic birch
        let baseZ = baseHeight / 2;
        let panelZ = panelHeight / 2;
        let panelThickness = .5; // 1/2" baltic birch
        let printedACMThickness = .125; // 1/8" ACM
        let radius = 6.5;

        // Max width of 29" if 4 panels because otherwise it can't fit on 4x8 sheet
        if (numPanels === 4 && panelWidth > 29) {
            panelWidth = 29;
            alert('Max width for 4 panels is 29"');
            document.getElementById('width_input').value = panelWidth;
        } else if (numPanels === 3 && panelWidth > 40) {
            panelWidth = 40;
            alert('Max width for 3 panels is 40"');
            document.getElementById('width_input').value = panelWidth;
        }

        // show axes to help with positioning
        // const axesHelper = new THREE.AxesHelper(100);
        // scene.add(axesHelper);
        
        

        panelWidthPlusGap = panelWidth + panelGap; // Add gap between panels - kinda wonky, but it simplifies all the positioning math without losing the panelWidth value
        graphicPanel1.scale.set(panelWidthPlusGap - panelGap, panelHeight, panelThickness);
        graphicPanel1.position.set(-panelWidthPlusGap / 2, panelZ, 0);
        roundedEnd1.scale.set(radius, baseHeight, radius);
        roundedEnd2.scale.set(radius, baseHeight, radius);
        straight1.scale.set(panelWidthPlusGap, baseHeight, radius);
        roundedEnd1.rotation.y = Math.PI;        
        roundedEnd1.position.set(0, baseZ, 0);
        roundedEnd2.position.set(panelWidthPlusGap, baseZ, 0);
        straight1.position.set(panelWidthPlusGap / 2, baseZ, 0);
        graphicPanel1.position.set(panelWidthPlusGap / 2, panelZ, 0);
        if (baseOnly) {
            // Create a graphic panel
            graphicPanel1.material = stripedMaterial;
            printedPanelFront.scale.set(0, 0, 0);
            printedPanelBack.scale.set(0, 0, 0);
        } else if (baseAndGraphicBacker) {
            // Create a blank panel
            graphicPanel1.material = mindfulGrayMaterial;
            printedPanelFront.scale.set(0, 0, 0);
            printedPanelBack.scale.set(0, 0, 0);
        } else if (baseAndGraphicBackerAndPrintedPanel) {
            // Create a blank panel
            graphicPanel1.material = mindfulGrayMaterial;
            // printedPanelFront.material = paintMaterial;
            // printedPanelBack.material = paintMaterial;
            printedPanelFront.scale.set(panelWidthPlusGap - panelGap, panelHeight - baseHeight - 4, printedACMThickness);
            printedPanelBack.scale.set(panelWidthPlusGap - panelGap, panelHeight - baseHeight - 4, printedACMThickness);
            printedPanelFront.position.set(panelWidthPlusGap / 2, (panelHeight - baseHeight - 4) / 2 + 5, panelThickness);
            printedPanelBack.position.set(panelWidthPlusGap / 2, (panelHeight - baseHeight - 4) / 2 + 5, -panelThickness);
        } else {
            // Create a graphic panel
            graphicPanel1.material = tricornBlackMaterial;
            printedPanelFront.scale.set(panelWidthPlusGap - panelGap, panelHeight - baseHeight - 4, printedACMThickness);
            printedPanelBack.scale.set(panelWidthPlusGap - panelGap, panelHeight - baseHeight - 4, printedACMThickness);
            printedPanelFront.position.set(panelWidthPlusGap / 2, (panelHeight - baseHeight - 4) / 2 + 5, panelThickness);
            printedPanelBack.position.set(panelWidthPlusGap / 2, (panelHeight - baseHeight - 4) / 2 + 5, -panelThickness);
        }

        const group1 = new THREE.Group();
        group1.add(graphicPanel1);
        group1.add(roundedEnd1);
        group1.add(straight1);
        group1.add(roundedEnd2);
        // name the printedPanelFront and printedPanelBack so we can change the texture later
        printedPanelFront.name = 'printedPanelFront';
        printedPanelBack.name = 'printedPanelBack';
        group1.add(printedPanelFront);
        group1.add(printedPanelBack);
        scene.add(group1);

        
        

        // Example usage: Clone the group 3 times and manage previous clones
        const newClones = cloneAndManageGroup(group1, 3);
        // Assign the cloned groups to individual variables
        const [group2, group3, group4] = newClones;

        // Now you can position them independently
        group2.rotation.y = Math.PI / 2;
        group2.position.set(0, 0, 0);
        group3.rotation.y = Math.PI / 2;
        group3.position.set(panelWidthPlusGap, 0, panelWidthPlusGap);
        group4.position.set(-panelWidthPlusGap, 0, -panelWidthPlusGap);

        // ------- traverse the cloned groups to put different graphics on the printed panels -------
        // Function to find and access the specific mesh within the cloned group so we can change the graphics
        group2.traverse(function (child) {
            if (child.isMesh && child.name === 'printedPanelFront') { // Check for the specific mesh
                child.material = sampleGraphicsMaterial32x78x3;
                if (baseAndGraphicBackerAndPrintedPanel) {
                    child.material = paintMaterial;
                }
            } else if (child.isMesh && child.name === 'printedPanelBack') { // Check for the specific mesh
                child.material = sampleGraphicsMaterial32x78x2;
                if (baseAndGraphicBackerAndPrintedPanel) {
                    child.material = paintMaterial;
                }
            }   
        });
        group3.traverse(function (child) {
            if (child.isMesh && child.name === 'printedPanelFront') { // Check for the specific mesh
                child.material = sampleGraphicsMaterial32x78x5;
                if (baseAndGraphicBackerAndPrintedPanel) {
                    child.material = paintMaterial;
                }
            } else if (child.isMesh && child.name === 'printedPanelBack') { // Check for the specific mesh
                child.material = sampleGraphicsMaterial32x78x4;
                if (baseAndGraphicBackerAndPrintedPanel) {
                    child.material = paintMaterial;
                }
            }
        });
        group4.traverse(function (child) {
            if (child.isMesh && child.name === 'printedPanelFront') { // Check for the specific mesh
                child.material = sampleGraphicsMaterial32x78x6;
                if (baseAndGraphicBackerAndPrintedPanel) {
                    child.material = paintMaterial;
                }
            } else if (child.isMesh && child.name === 'printedPanelBack') { // Check for the specific mesh
                child.material = sampleGraphicsMaterial32x78x6;
                if (baseAndGraphicBackerAndPrintedPanel) {
                    child.material = paintMaterial;
                }
            }
        });
        if (baseAndGraphicBackerAndPrintedPanel) {
            printedPanelFront.material = paintMaterial;
            printedPanelBack.material = paintMaterial;
        } else {
            printedPanelFront.material = sampleGraphicsMaterial32x78x1;
            printedPanelBack.material = sampleGraphicsMaterial32x78x4;
        }
        // --------------------------------------------------------------------------------------------

        // hide clones that are not needed
        if (numPanels === 2) {
            group3.visible = false;
            group4.visible = false;
            if (topClamp2 && topClamp3) {
                topClamp2.visible = false;
                topClamp3.visible = false;
            }
        } else if (numPanels === 3) {
            group4.visible = false
            if (topClamp2 && topClamp3) {
                topClamp2.visible = true;
                topClamp3.visible = false;
            }
        } else if (numPanels === 4) {
            if (topClamp2 && topClamp3) {
                topClamp2.visible = true;
                topClamp3.visible = true;
            }
        }

        // position the topClamp
        if (topClamp) {
            // Update position
            topClamp.position.set(5, panelHeight - .625, .375);
        }
        if (topClamp2) {
            topClamp2.position.set(panelWidth - 3.5, panelHeight - .625, -.375);
        }
        if (topClamp3) {
            topClamp3.position.set(-5, panelHeight - .625, - panelWidth - 1.875);
        }

    }
    document.querySelector('#zig-zag-builder').addEventListener('change', zigZagBuilder);

    document.querySelector('#swap-person').addEventListener('click', loadRandomModel);

    // on page load run the function
    zigZagBuilder();
    



}