import React from "react";
import { currencyWithDecimals } from "../currency";

export default class DeliveryForm extends React.Component {
  render() {
    const { miles, price, milesChangeHandler } = this.props;

    return (
      <div className="grid grid-cols-12">
        <div className="col-span-8 sm:col-start-2">
          <p className="self-end font-bold border-b border-black">
            Delivery Required
          </p>
        </div>
        <div className="col-span-4 sm:col-span-3">
          <p className="font-bold text-right border-b border-black">
            {currencyWithDecimals(price)}
          </p>
        </div>

        <div className="col-span-7 sm:col-start-2">
          <p className="mt-2 mb-4 text-sm">
            One or more items in your quote require on-site installation, so our
            team will deliver your products via truck and perform the
            installation.
          </p>
          <div className="mt-2">
            <label
              htmlFor="miles"
              className="block text-sm font-medium text-gray-700"
            >
              Enter{" "}
              <a
                href="https://www.google.com/maps/dir//North+Newton,+Kansas/"
                className="hover:underline"
              >
                distance from delivery location to North Newton, KS
              </a>
              :
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="number"
                step="1"
                name="miles"
                id="miles"
                className="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                placeholder="0"
                value={miles}
                onChange={milesChangeHandler}
                required={true}
                aria-describedby="miles"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="miles">
                  miles
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 col-start-10 sm:col-span-2 sm:col-start-11">
          <img
            src="/assets/img/icons/quickship-square.svg"
            className="mr-4"
            alt=""
          />
        </div>
      </div>
    );
  }
}
