export function currencyWithDecimals(num) {
  var currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return currencyFormatter.format(num);
}

export function currencyWithoutDecimals(num) {
  var currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return currencyFormatter.format(num);
}
