import { doc } from 'prettier';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { add, cameraPosition, label } from 'three/examples/jsm/nodes/Nodes.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/Addons.js';

export default function render(element) {
    // Get size of container
    let renderContainer = document.getElementById('render-container').getBoundingClientRect();

    // Set up scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, renderContainer.width / renderContainer.width, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(renderContainer.width, renderContainer.width); // Square aspect ratio based on width
    renderer.setClearColor(0x1f1e1c, 0);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Choose a tone mapping method
    renderer.toneMappingExposure = .9; // Adjust exposure
    renderer.physicallyCorrectLights = true; // Enable physical lighting
    // Enable shadow rendering
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: for softer shadows

    element.appendChild(renderer.domElement);

    const canvas = renderer.domElement;

    // Add Orbit Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // show the axes
    // const axesHelper = new THREE.AxesHelper(80);
    // scene.add(axesHelper);

    // Set the point around which the camera orbits
    const target = new THREE.Vector3(0, 24, 0); // Example target
    controls.target.copy(target);
    controls.update();

    // Prevent camera from going below ground
    const degrees = 80;
    const radians = degrees * Math.PI / 180;
    controls.maxPolarAngle = radians;

    // Add HDRI environment map
    const hdrEquirect = new RGBELoader().load(
        "/assets/img/threejs/hdr/rostock_laage_airport_1k.hdr",
        () => {
            hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
        }
    );

    // Create wood-like material
    const woodTexture = new THREE.TextureLoader().load('/assets/img/threejs/materials/cherry.jpg');
    const woodMaterial = new THREE.MeshPhysicalMaterial({
        map: woodTexture,
        clearcoat: 1,
    });
    woodTexture.colorSpace = THREE.SRGBColorSpace;

    const paintMaterial = new THREE.MeshStandardMaterial({
        color: '#606E7B'
    });
    const paintMaterialDarker = new THREE.MeshPhysicalMaterial({
        color: 0x586168,
        metalness: 0,
        roughness: 0.1,
    });
    const whiteMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xEDECE6,
        metalness: 0,
        roughness: 0.1,
    });

    // Create glass material
    const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xa8ccd7,
        metalness: 0,
        roughness: 0.1,
        transmission: .9, // transparency
        opacity: 0.3,
        transparent: true,
        reflectivity: 0.9,
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        envMap: hdrEquirect,
    });

    // Create an emmissive material
    const emissiveMaterial = new THREE.MeshPhysicalMaterial({
        color: 0x000000,
        emissive: 0xfdfbf2,
        emissiveIntensity: 2,
    });

    // Create display
    let base = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterialDarker);
    let floor = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let side1 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let side2 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let back = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let top = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let door = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    // add a second door for over 34" width
    let door2 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let header = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let shelf = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);

    // Add-ons
    let leftWing = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let leftWingBase = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterialDarker);
    let rightWing = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let rightWingBase = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterialDarker);
    let riser = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);

    // Add detail
    let lightPanel = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), emissiveMaterial);
    // lock material
    const lockMaterial = new THREE.MeshStandardMaterial({ color: 0x47413E }); // brass: #CF9645; bronze: #47413E
    let lock = new THREE.Mesh(new THREE.CylinderGeometry(.5, .5, .5), lockMaterial);


    // Create corners
    // Function to create a rounded box geometry
    function createRoundedBox(width, height, depth, radius, segments) {
        const shape = new THREE.Shape();
        const eps = 0.00001;
        const radius0 = radius - eps;
        shape.absarc(eps, eps, eps, -Math.PI / 2, -Math.PI, true);
        shape.absarc(eps, height - radius * 2, eps, Math.PI, Math.PI / 2, true);
        shape.absarc(width - radius * 2, height - radius * 2, eps, Math.PI / 2, 0, true);
        shape.absarc(width - radius * 2, eps, eps, 0, -Math.PI / 2, true);
        const geometry = new THREE.ExtrudeGeometry(shape, {
            depth: depth - radius * 2,
            bevelEnabled: true,
            bevelSegments: segments,
            steps: 1,
            bevelSize: radius,
            bevelThickness: radius,
            curveSegments: segments
        });
        geometry.center();
        return geometry;
    }
    // Create a rounded box geometry and mesh
    const roundedBoxWIdth = 1.5;
    const roundedBoxHeight = 84; // set to reasonable height so corners don't look crazy when scaled
    const roundedBoxDepth = 1.5;
    const roundedBoxRadius = 0.25;
    const roundedBoxSegments = 2;
    const roundedBoxGeometry = createRoundedBox(roundedBoxWIdth, roundedBoxHeight, roundedBoxDepth, roundedBoxRadius, roundedBoxSegments);

    const corner1 = new THREE.Mesh(roundedBoxGeometry, paintMaterialDarker);
    scene.add(corner1);
    const corner2 = new THREE.Mesh(roundedBoxGeometry, paintMaterialDarker);
    scene.add(corner2);
    const corner3 = new THREE.Mesh(roundedBoxGeometry, paintMaterialDarker);
    scene.add(corner3);
    const corner4 = new THREE.Mesh(roundedBoxGeometry, paintMaterialDarker);
    scene.add(corner4);


    // Create right triangle for wings tops and bottoms
    var triangleShape = new THREE.Shape();
    triangleShape.moveTo(0, 0);
    triangleShape.lineTo(0, 1);
    triangleShape.lineTo(1, 1);
    triangleShape.lineTo(0, 0); // close the triangleShape
    var extrudeSettings = {
        steps: 1,
        depth: 1,
        bevelEnabled: false
    };
    var triangleGeometry = new THREE.ExtrudeGeometry(triangleShape, extrudeSettings);
    const leftWingTop = new THREE.Mesh(triangleGeometry, woodMaterial);
    const leftWingBottom = new THREE.Mesh(triangleGeometry, woodMaterial);
    const rightWingTop = new THREE.Mesh(triangleGeometry, woodMaterial);
    const rightWingBottom = new THREE.Mesh(triangleGeometry, woodMaterial);
    scene.add(leftWingTop);
    scene.add(leftWingBottom);
    scene.add(rightWingTop);
    scene.add(rightWingBottom);


    const artifactRiser = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), woodMaterial);
    const labelRail = new THREE.Mesh(triangleGeometry, woodMaterial);
    scene.add(artifactRiser);
    scene.add(labelRail);
    
    const glassShelf = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), glassMaterial);
    scene.add(glassShelf);
    

    // Enable shadow casting for display
    base.castShadow = true;
    floor.castShadow = true;
    side1.castShadow = true;
    side2.castShadow = true;
    back.castShadow = true;
    top.castShadow = true;
    door.castShadow = true;
    header.castShadow = true;
    leftWing.castShadow = true;
    rightWing.castShadow = true;
    leftWingTop.castShadow = true;
    leftWingBottom.castShadow = true;
    rightWingTop.castShadow = true;
    rightWingBottom.castShadow = true;
    



    // Add display to scene
    scene.add(base);
    scene.add(floor);
    scene.add(side1);
    scene.add(side2);
    scene.add(back);
    scene.add(top);
    scene.add(door);
    scene.add(door2);
    scene.add(header);
    scene.add(shelf);
    scene.add(lightPanel);
    scene.add(lock);
    scene.add(leftWing);
    scene.add(rightWing);
    scene.add(leftWingBase);
    scene.add(rightWingBase);
    scene.add(riser);


    // Create glass case front
    let glass = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), glassMaterial);

    // // Add glass case front to scene
    scene.add(glass);

    const models = [
        { path: '/assets/img/threejs/models/Woman.glb', position: { x: 30, y: 0, z: -40 }, scale: { x: 37, y: 37, z: 37 } },
    ];


    // load some people into the model for scale (and for fun!)
    let currentPerson;
    function loadRandomModel() {
        if (currentPerson) {
            scene.remove(currentPerson);
        }

        const randomIndex = Math.floor(Math.random() * models.length);
        const selectedModel = models[randomIndex];

        const gltfLoader = new GLTFLoader();
        gltfLoader.load(selectedModel.path, function (gltf) {
            currentPerson = gltf.scene;
            currentPerson.position.set(selectedModel.position.x, selectedModel.position.y, selectedModel.position.z);
            currentPerson.scale.set(selectedModel.scale.x, selectedModel.scale.y, selectedModel.scale.z);
            scene.add(currentPerson);
            
            // Enable shadows for all meshes in the model
            currentPerson.traverse(function (node) {
                if (node.isMesh) {
                    node.castShadow = true;
                    node.receiveShadow = true;
                }
            });

        }, undefined, function (error) {
            console.error(error);
        });
    }

    loadRandomModel();

 



    // Add lighting
    const light = new THREE.DirectionalLight(0xF4F4F2, 2);
    light.position.set(30, 150, 60);
    // Enable shadow casting for light
    light.castShadow = true;
    // Adjust shadow camera frustum for better shadow quality
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 200;
    light.shadow.camera.left = -200;
    light.shadow.camera.right = 200;
    light.shadow.camera.top = 200;
    light.shadow.camera.bottom = -200;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    // Add ambient light to soften shadows
    const ambientLight = new THREE.AmbientLight(0xF4F4F2, 3);
    scene.add(ambientLight);

    // Add a ground plane to receive shadows
    const groundGeometry = new THREE.PlaneGeometry(200, 200);
    const groundMaterial = new THREE.MeshStandardMaterial({ color: 0xeeeeee });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.01; // Slightly below the pedestals
    ground.receiveShadow = true;
    scene.add(ground);


    // Position camera
    camera.position.set(-24, 55, 93); // looks nice for this scene
    controls.update();

    // Render loop
    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    }
    animate();

    // Handle window resizing
    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize() {
        renderContainer = document.getElementById('render-container').getBoundingClientRect();
        camera.aspect = renderContainer.width / renderContainer.width;
        camera.updateProjectionMatrix();
        renderer.setSize(renderContainer.width, renderContainer.width);
    }


    function displayCaseBuilder() {
        let width = Number(document.getElementById('width_input').value);
        let height = Number(document.getElementById('height_input').value);
        let depth = Number(document.getElementById('depth_input').value);
        let caseInteriorFloorHeight = Number(document.getElementById('case_interior_floor_height_input').value); 
        let thickness = .75;
        let doorHeight = caseInteriorFloorHeight - baseHeight;
        let glassHeight = height - headerHeight - doorHeight - baseHeight;
        
        // console.log(camera.position); // arrange the scene then use this to figure out the camera position

        // scale the display
        base.scale.set(width - 2, baseHeight, depth - 2);
        base.position.set(0, baseHeight / 2, 0);
        floor.scale.set(width - thickness, thickness, depth - thickness);
        floor.position.set(0, thickness / 2 + baseHeight, 0);
        side1.scale.set(thickness, height - baseHeight, depth - thickness);
        side1.position.set((width - thickness) / 2, baseHeight + (height - baseHeight) / 2, 0);
        side2.scale.set(thickness, height - baseHeight, depth - thickness);
        side2.position.set(-(width - thickness) / 2, baseHeight + (height - baseHeight) / 2, 0);
        back.scale.set(width - thickness * 2, height - baseHeight, thickness);
        back.position.set(0, baseHeight + (height - baseHeight) / 2, - depth / 2 + thickness);
        top.scale.set(width - thickness, thickness, depth - thickness);
        top.position.set(0, height - thickness / 2, 0);
        door.scale.set(width - thickness * 2 - .25, doorHeight, thickness);
        door.position.set(0, baseHeight + doorHeight / 2, depth / 2 - thickness);
        shelf.scale.set(width - thickness * 2, thickness, depth - thickness * 2 - .5);
        shelf.position.set(0, baseHeight + doorHeight - thickness / 2, -.25);
        header.scale.set(width - thickness * 2, headerHeight, thickness);
        header.position.set(0, height - headerHeight / 2, depth / 2 - thickness);
        glass.scale.set(width - thickness * 2, glassHeight, glassThickness);
        glass.position.set(0, baseHeight + doorHeight + glassHeight / 2, depth / 2 - glassThickness - 1);
        lightPanel.scale.set(width - thickness * 2, thickness, depth - thickness * 2);
        lightPanel.position.set(0, height - thickness / 2 - 5, 0);
        
        // Corners
        corner1.scale.set(1, (height - baseHeight) / roundedBoxHeight, 1); // scale the height of the rounded box based on the initial height (which isn't 1 in this case)
        corner1.position.set(width / 2, height / 2 + baseHeight / 2, depth / 2);
        corner2.scale.set(1, (height - baseHeight) / roundedBoxHeight, 1);
        corner2.position.set(-width / 2, height / 2 + baseHeight / 2, depth / 2);
        corner3.scale.set(1, (height - baseHeight) / roundedBoxHeight, 1);
        corner3.position.set(width / 2, height / 2 + baseHeight / 2, -depth / 2);
        corner4.scale.set(1, (height - baseHeight) / roundedBoxHeight, 1);
        corner4.position.set(-width / 2, height / 2 + baseHeight / 2, -depth / 2);

        // lock
        // rotate lock
        lock.rotation.x = Math.PI / 2;
        lock.position.set(width / 2 - thickness * 2 - 1.5, doorHeight, depth / 2 - thickness + .25);
        
        // if width is greater than 34", make two doors
        if (width > 34) {
            door.scale.set((width - thickness * 2) / 2, doorHeight, thickness);
            door.position.set((width - thickness * 2) / 4 + .25, baseHeight + doorHeight / 2, depth / 2 - thickness);            
            door2.scale.set((width - thickness * 2) / 2, doorHeight, thickness);
            door2.position.set(-(width - thickness * 2) / 4, baseHeight + doorHeight / 2, depth / 2 - thickness);
            lock.position.set(- 1.5, doorHeight, depth / 2 - thickness + .25);
        } else {
            door2.scale.set(0, 0, 0);
        }  

        // Add-ons
        if (document.getElementById('left-wing').value === 'yes') {
            leftWing.scale.set(depth * Math.sqrt(2) - thickness, height - baseHeight, thickness);
            leftWing.rotation.y = -Math.PI / 4;
            leftWing.position.set(-width / 2 - depth / 2, baseHeight + (height - baseHeight) / 2, -thickness);

            leftWingBase.scale.set(depth * Math.sqrt(2) - thickness, baseHeight, thickness);
            leftWingBase.rotation.y = -Math.PI / 4;
            leftWingBase.position.set(-width / 2 - depth / 2 + thickness * Math.sqrt(2), baseHeight / 2, - thickness * Math.sqrt(2));

            leftWingTop.rotation.x = -Math.PI / 2;
            leftWingTop.rotation.z = - Math.PI / 2;
            leftWingTop.scale.set(depth - thickness * Math.sqrt(2), depth - thickness * Math.sqrt(2), thickness);
            leftWingTop.position.set(-depth  - width / 2 + thickness * Math.sqrt(2), height - thickness - .5, -depth / 2);
        
            leftWingBottom.rotation.x = -Math.PI / 2;
            leftWingBottom.rotation.z = - Math.PI / 2;
            leftWingBottom.scale.set(depth - thickness * Math.sqrt(2), depth - thickness * Math.sqrt(2), thickness);
            leftWingBottom.position.set(-depth - width / 2 + thickness * Math.sqrt(2), baseHeight, -depth / 2);

            base.scale.set(width, baseHeight, depth - 2);
        } else {
            leftWing.scale.set(0, 0, 0);
            leftWingBase.scale.set(0, 0, 0);
            leftWingTop.scale.set(0, 0, 0);
            leftWingBottom.scale.set(0, 0, 0);
        }
        if (document.getElementById('right-wing').value === 'yes') {
            rightWing.scale.set(depth * Math.sqrt(2) - thickness, height - baseHeight, thickness);
            rightWing.rotation.y = Math.PI / 4;
            rightWing.position.set(width / 2 + depth / 2, baseHeight + (height - baseHeight) / 2, -thickness);
            
            rightWingBase.scale.set(depth * Math.sqrt(2) - thickness, baseHeight, thickness);
            rightWingBase.rotation.y = Math.PI / 4;
            rightWingBase.position.set(width / 2 + depth / 2 - thickness * Math.sqrt(2), baseHeight / 2, - thickness * Math.sqrt(2));

            rightWingTop.rotation.x = - Math.PI / 2;
            rightWingTop.rotation.z = - Math.PI / 2;
            rightWingTop.rotation.y = Math.PI;
            rightWingTop.scale.set(depth - thickness * Math.sqrt(2), depth - thickness * Math.sqrt(2), thickness);
            rightWingTop.position.set(depth + width / 2 - thickness * Math.sqrt(2), height - .5, -depth / 2);

            rightWingBottom.rotation.x = - Math.PI / 2;
            rightWingBottom.rotation.z = - Math.PI / 2;
            rightWingBottom.rotation.y = Math.PI;
            rightWingBottom.scale.set(depth - thickness * Math.sqrt(2), depth - thickness * Math.sqrt(2), thickness);
            rightWingBottom.position.set(depth + width / 2 - thickness * Math.sqrt(2), baseHeight + .5, -depth / 2);
            
            base.scale.set(width, baseHeight, depth - 2);

        } else {
            rightWing.scale.set(0, 0, 0);
            rightWingBase.scale.set(0, 0, 0);
            rightWingTop.scale.set(0, 0, 0);
            rightWingBottom.scale.set(0, 0, 0);
        }
        
        if (document.getElementById('riser').value === 'yes') {
            let artifactRiserDepth;
            let labelRailAngle = 30; // degrees            
            let labelRailHeight = 7; // height of the graphic
            let artifactRiserHeight = labelRailHeight * Math.sin(labelRailAngle * Math.PI / 180);
            let labelRailDepth = labelRailHeight * Math.cos(labelRailAngle * Math.PI / 180);

            artifactRiserDepth = depth - labelRailDepth - thickness - 1.25;
            
            artifactRiser.scale.set(width - thickness * 2, artifactRiserHeight, artifactRiserDepth);
            artifactRiser.position.set(0, baseHeight + doorHeight + artifactRiserHeight / 2, -(depth - artifactRiserDepth) / 2 + thickness);

            labelRail.rotation.x = -Math.PI / 2;
            labelRail.rotation.y = -Math.PI / 2;
            labelRail.scale.set(artifactRiserHeight, labelRailDepth, width - thickness * 2);
            labelRail.position.set(width / 2 - thickness, baseHeight + doorHeight, (depth - thickness) / 2 - 1);
        } else {
            artifactRiser.scale.set(0, 0, 0);
            labelRail.scale.set(0, 0, 0);
        }

        
        let shelfHeight = document.getElementById('shelf_height_input').value;
        let shelfDepth = document.getElementById('shelf_depth_input').value;
        if (document.getElementById('add-shelf').value === 'yes') {
            glassShelf.scale.set(width - thickness * 2, .25, shelfDepth);
            glassShelf.position.set(0, shelfHeight, - (depth - shelfDepth) / 2 + thickness);
        } else {
            glassShelf.scale.set(0, 0, 0);
        }


    }

    displayCaseBuilder();

    document.querySelector('#display-case-builder').addEventListener('change', displayCaseBuilder);
    document.querySelector('#swap-person').addEventListener('click', loadRandomModel);
}