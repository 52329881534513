import React from "react";
import Form from "./components/form";
import PedestalBuilder from "./components/pedestals";
import Portal from "./components/portal";
import Mini from "./components/mini";
import Products from "./products.json";
import { loadFromStorage, saveToStorage } from "./storage";
import * as analytics from "./analytics";

function loadLineItem(productID, lineItemData) {
  let lineItem = {
    // productID
    // price: null,
    // title: null,
    // description: null,
    // img: null,
    // quantity: null,
    // addons: {},
    // notes
    // delivery
    priceWithAddons: function () {
      let total = this.price;
      Object.keys(this.addonsEnabled || {}).forEach((addonID) => {
        total += standardProductInfo.addons[addonID].price;
      });
      return total;
    },
    subtotal: function () {
      return this.priceWithAddons() * this.quantity;
    },
    singleFieldDescription: function () {
      let description = this.title;
      if (this.description) {
        description += "\n  " + this.description;
      }
      if (this.notes) {
        description += "\n  Notes: " + this.notes;
      }
      if (this.customizations) {
        description += "\n  Customizations:";
        description += Object.keys(this.customizations)
          .map((key) => {
            return `${key}: ${this.customizations[key]}`;
          })
          .join("\n    ");
      }
      return description;
    },
  };

  // Load the standard product info if it exists
  const standardProductInfo = Products[productID] || {};

  // Override the data in this order
  lineItem = { ...lineItem, ...standardProductInfo, ...lineItemData };

  // Set some specific properties for easy retrieval later
  lineItem.productID = productID;
  lineItem.standardProduct = standardProductInfo;

  // Bind the methods so they work right
  lineItem.priceWithAddons.bind(lineItem);
  lineItem.subtotal.bind(lineItem);

  return lineItem;
}

function loadLineItems(lineItems) {
  return Object.entries(lineItems).map(([productID, lineItemData]) =>
    loadLineItem(productID, lineItemData),
  );
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddonChanged = this.handleAddonChanged.bind(this);
    this.handleQuantityChanged = this.handleQuantityChanged.bind(this);
    this.handleNotesChanged = this.handleNotesChanged.bind(this);
    this.handleContactFieldChange = this.handleContactFieldChange.bind(this);
    this.addToQuote = this.addToQuote.bind(this);

    this.state = loadFromStorage();
  }
  updateItems(items) {
    this.setState({ items }, () => {
      saveToStorage(this.state);
    });
  }

  addToQuote(productID, customInfo) {
    const { items } = this.state;
    const existingItem = items[productID];
    if (existingItem) {
      existingItem.quantity++;
    } else {
      items[productID] = {
        quantity: 1,
      };
    }

    items[productID] = { ...items[productID], ...customInfo };

    analytics.addToCart(loadLineItem(productID, items[productID]));

    this.updateItems(items);
  }
  handleAddonChanged(productID, addonID, added) {
    const { items } = this.state;
    const item = items[productID];
    if (!item.addonsEnabled) item.addonsEnabled = {};
    item.addonsEnabled[addonID] = added;
    // if false, remove it completely
    if (!added) delete item.addonsEnabled[addonID];
    if (!item.addonsEnabled) delete item.addonsEnabled;
    this.updateItems(items);
  }
  handleQuantityChanged(productID, quantity) {
    const { items } = this.state;

    if (quantity === 0) {
      // const title = items[productID].title || Products[productID].title;
      if (!confirm(`Do you want to remove this from your quote?`)) {
        return;
      }
      delete items[productID];
    } else {
      items[productID].quantity = quantity;
    }

    this.updateItems(items);
  }
  handleNotesChanged(productID, notes) {
    const { items } = this.state;
    items[productID].notes = notes;
    this.updateItems(items);
  }
  handleContactFieldChange(event) {
    const { contact_fields } = this.state;
    if (event.target.type === "checkbox") {
      contact_fields[event.target.name] = event.target.checked;
    } else {
      contact_fields[event.target.name] = event.target.value;
    }
    this.setState({ contact_fields: contact_fields }, () => {
      saveToStorage(this.state);
    });
  }
  render() {
    const lineItems = loadLineItems(this.state.items);

    return (
      <div>
        <Mini lineItems={lineItems} />

        {this.props.quoteFormElement ? (
          <Portal renderTarget={this.props.quoteFormElement}>
            <Form
              lineItems={lineItems}
              contact_fields={this.state.contact_fields}
              onContactFieldChange={this.handleContactFieldChange}
              onAddonsChange={this.handleAddonChanged}
              onQuantityChange={this.handleQuantityChanged}
              onNotesChange={this.handleNotesChanged}
            />
          </Portal>
        ) : null}

        {this.props.pedestalBuilderElement ? (
          <Portal renderTarget={this.props.pedestalBuilderElement}>
            <PedestalBuilder addToQuote={this.addToQuote} />
          </Portal>
        ) : null}
      </div>
    );
  }
}
