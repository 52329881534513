import React from "react";
import FormEmpty from "./form_empty";
import ContactInformationForm from "./contact_information_form";
import DeliveryForm from "./delivery_form";
import LineItem from "./line_item";
import { currencyWithDecimals } from "../currency";

export default class Form extends React.Component {
  lineItemsToFormText(lineItems, shippingInfo) {
    let textLineItems = "";

    lineItems.forEach((lineItem) => {
      textLineItems += `${lineItem.singleFieldDescription()}\n  Price: $${
        lineItem.price
      }, Quantity: ${lineItem.quantity}\n\n`;

      // We should only have "true" addons at this point
      Object.keys(lineItem.addonsEnabled || {}).forEach((addonID) => {
        const addon = lineItem.standardProduct.addons[addonID];
        textLineItems += `  Add-on: ${addon.title}\n    Price: $${addon.price}, Quantity: ${lineItem.quantity}\n\n`;
      });
    });

    const { shippingTotal, deliveryTotal, deliveryRequired } = shippingInfo;
    if (deliveryRequired) {
      textLineItems += `Estimated Delivery: $${deliveryTotal}\n`;
    } else {
      textLineItems += `Estimated Shipping: $${shippingTotal}\n`;
    }

    const leadTimeWeeks = this.calculateLeadTime(lineItems);
    if (leadTimeWeeks > 0) {
      textLineItems += `Estimated Lead Time: ${leadTimeWeeks} week${
        leadTimeWeeks > 1 ? "s" : ""
      }\n`;
    }

    return textLineItems;
  }
  calculateLeadTime(lineItems) {
    // Find the longest lead time of all line items
    let leadTimeWeeks = 0;

    lineItems.forEach((lineItem) => {
      leadTimeWeeks = Math.max(leadTimeWeeks, lineItem.leadTimeWeeks || 0);
    });

    return leadTimeWeeks;
  }
  calculateCHPLDiscount(lineItems) {
    let individualCHPLCost = 0;
    let CHPLSqFtTotal = 0;
    let chplQuantity = 0;

    lineItems.forEach((lineItem) => {
      if (lineItem.chplSqFt) {
        individualCHPLCost +=
          calculate_chpl_cost(lineItem.chplSqFt, 1) * lineItem.quantity;
        CHPLSqFtTotal += lineItem.chplSqFt * lineItem.quantity;
        chplQuantity += lineItem.quantity;
      }
    });

    
    
    let bulkCHPLCost = 0;
    if (chplQuantity != 0) {
      bulkCHPLCost = calculate_chpl_cost(CHPLSqFtTotal, chplQuantity);
    }
    console.log("chplqty", chplQuantity);
    console.log("bulk", bulkCHPLCost);
    console.log("individual", individualCHPLCost);

    return individualCHPLCost - bulkCHPLCost;
  }
  calculateTotal(lineItems, shippingInfo) {
    let total = 0;

    lineItems.forEach((lineItem) => {
      total += lineItem.price * lineItem.quantity;
      Object.keys(lineItem.addonsEnabled || {}).forEach((addonID) => {
        const addon = lineItem.standardProduct.addons[addonID];
        total += addon.price * lineItem.quantity;
      });
    });

    const { shippingTotal, deliveryTotal, deliveryRequired } = shippingInfo;
    total += shippingTotal + deliveryTotal;

    total += this.calculateCHPLDiscount(lineItems);

    return total;
  }
  shippingInfoFromLineItems(lineItems, miles) {
    let shippingTotal = 0;
    let deliveryTotal = 0;
    let deliveryRequired = false;

    lineItems.forEach((lineItem) => {
      deliveryRequired = deliveryRequired || lineItem.delivery;
      shippingTotal += (lineItem.shipping || 0) * lineItem.quantity;
    });

    if (deliveryRequired) deliveryTotal = (miles || 0) * 3.5 + 50;

    return { shippingTotal, deliveryTotal, deliveryRequired };
  }
  render() {
    const {
      lineItems,
      contact_fields,
      onAddonsChange,
      onQuantityChange,
      onNotesChange,
      onContactFieldChange,
    } = this.props;

    if (Object.keys(lineItems).length < 1) {
      return <FormEmpty />;
    }

    const shippingInfo = this.shippingInfoFromLineItems(
      lineItems,
      contact_fields.miles,
    );
    const { shippingTotal, deliveryTotal, deliveryRequired } = shippingInfo;
    const lineItemsText = this.lineItemsToFormText(lineItems, shippingInfo);
    const leadTimeWeeks = this.calculateLeadTime(lineItems);
    const chplDiscount = this.calculateCHPLDiscount(lineItems);

    return (
      <form name="quote-request" method="POST" action="/quote/thank-you/">
        {/* To connect to Netlify form */}
        <input
          type="hidden"
          name="subject"
          data-remove-prefix
          value={`Upland Exhibits Quote Request [#%{submissionId}] - ${contact_fields.organization_name}`}
        />
        <input type="hidden" name="form-name" value="quote-request" />
        <input type="hidden" name="line_items" value={lineItemsText} />

        <div className="hidden grid-cols-12 gap-0 mb-4 sm:grid print:grid print:text-sm">
          <div className="col-span-7 font-bold border-b-2 border-black">
            Product
          </div>
          <div className="col-span-1 font-bold text-right border-b-2 border-black">
            Price
          </div>
          <div className="col-span-2 font-bold text-center border-b-2 border-black">
            Quantity
          </div>
          <div className="col-span-2 font-bold text-right border-b-2 border-black">
            Subtotal
          </div>
        </div>

        {lineItems.map((lineItem) => (
          <LineItem
            key={lineItem.productID}
            lineItem={lineItem}
            addonChangeHandler={onAddonsChange}
            quantityChangeHandler={onQuantityChange}
            notesChangeHandler={onNotesChange}
          />
        ))}

        <div className="mt-8 space-y-8">
          {chplDiscount > 0 && (
            <div className="grid grid-cols-12">
              <div className="col-span-8 sm:col-start-2">
                <p className="self-end font-bold text-red-600 border-b border-black">
                  CHPL Graphics Bulk Discount
                </p>
              </div>
              <div className="col-span-4 sm:col-span-3">
                <p className="font-bold text-right text-red-600 border-b border-black">
                  {currencyWithDecimals(chplDiscount * -1)}
                </p>
              </div>
              <div className="col-span-7 sm:col-start-2">
                <p className="mt-2 mb-4 text-sm italic text-gray-600">
                  You saved {currencyWithDecimals(chplDiscount)} on CHPL
                  graphics by ordering multiple.
                </p>
              </div>
            </div>
          )}

          {leadTimeWeeks > 0 && (
            <div className="grid grid-cols-12">
              <div className="col-span-8 sm:col-start-2">
                <p className="self-end font-bold border-b border-black">
                  Lead Time
                </p>
              </div>
              <div className="col-span-4 sm:col-span-3">
                <p className="font-bold text-right border-b border-black">
                  {leadTimeWeeks} week{leadTimeWeeks > 1 ? "s" : ""}
                </p>
              </div>
              <div className="col-span-7 sm:col-start-2">
                <p className="mt-2 mb-4 text-sm italic text-gray-600">
                  Estimate based on the longest lead time of all items in your
                  quote.
                </p>
              </div>
            </div>
          )}

          {deliveryRequired ? (
            <DeliveryForm
              miles={contact_fields.miles}
              price={deliveryTotal}
              milesChangeHandler={onContactFieldChange}
            />
          ) : (
            <div className="grid grid-cols-12">
              <div className="col-span-8 sm:col-start-2">
                <p className="self-end font-bold border-b border-black">
                  Shipping
                </p>
              </div>
              <div className="col-span-4 sm:col-span-3">
                <p className="font-bold text-right border-b border-black">
                  {currencyWithDecimals(shippingTotal)}
                </p>
              </div>
              <div className="col-span-7 sm:col-start-2">
                <p className="mt-2 mb-4 text-sm italic text-gray-600">
                  Shipping has been estimated for your quote.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-12 mt-12">
          <div className="col-span-8 sm:col-start-2">
            <p className="self-end font-bold border-b-2 border-black">Total</p>
          </div>
          <div className="col-span-4 sm:col-span-3">
            <p className="font-bold text-right border-b-2 border-black">
              {currencyWithDecimals(
                this.calculateTotal(lineItems, shippingInfo),
              )}
            </p>
          </div>
        </div>

        <div className="pt-8 mt-12 border-t border-gray-400 border-dotted sm:ml-16 print:mx-8 print:border-0 print:py-0">
          <ContactInformationForm
            contact_fields={contact_fields}
            changeHandler={onContactFieldChange}
          />
        </div>

        <button
          type="submit"
          className="block px-4 py-2 mx-auto mt-8 mb-12 font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 print:hidden"
        >
          Submit
        </button>
      </form>
    );
  }
}
