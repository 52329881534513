const storageKey = "upland_quote";

export function loadFromStorage() {
  const stored = JSON.parse(localStorage.getItem(storageKey) || "{}");
  // At least make the top-level fields exist if they don't already
  if (!stored.items) stored.items = {};
  if (!stored.contact_fields) stored.contact_fields = {};
  return stored;
}

export function saveToStorage(state) {
  localStorage.setItem(storageKey, JSON.stringify(state));
}

export function clearStorage() {
  localStorage.removeItem(storageKey);
}
