import { doc } from 'prettier';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { add } from 'three/examples/jsm/nodes/Nodes.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

export default function render(element) {
    // Get size of container
    let renderContainer = document.getElementById('render-container').getBoundingClientRect();
    
    // Set up scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, renderContainer.width / renderContainer.width, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(renderContainer.width, renderContainer.width); // Square aspect ratio based on width
    renderer.setClearColor(0x1f1e1c, 0);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Choose a tone mapping method
    renderer.toneMappingExposure = .9; // Adjust exposure
    renderer.physicallyCorrectLights = true; // Enable physical lighting
    // Enable shadow rendering
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: for softer shadows

    element.appendChild(renderer.domElement);

    const canvas = renderer.domElement;

    // Add Orbit Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    
    // Set the point around which the camera orbits
    const target = new THREE.Vector3(0, 24, 0); // Example target
    controls.target.copy(target);
    controls.update();
    
    // Prevent camera from going below ground
    const degrees = 80;
    const radians = degrees * Math.PI / 180;
    controls.maxPolarAngle = radians; 

    // Add HDRI environment map
    const hdrEquirect = new RGBELoader().load(
        "/assets/img/threejs/hdr/rostock_laage_airport_1k.hdr",
        () => {
            hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
        }
    );

    // Create wood-like material
    const woodTexture = new THREE.TextureLoader().load('/assets/img/threejs/materials/cherry.jpg');
    const woodMaterial = new THREE.MeshPhysicalMaterial({
        map: woodTexture,
        clearcoat: 1,
    });
    woodTexture.colorSpace = THREE.SRGBColorSpace;

    // Create glass material
    const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xa8ccd7,
        metalness: 0,
        roughness: 0.1,
        transmission: .9, // transparency
        opacity: 0.3,
        transparent: true,
        reflectivity: 0.9,
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        envMap: hdrEquirect,
    });



    // Create pedestals
    const largeBox = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), woodMaterial);
    const mediumBox = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), woodMaterial);
    const smallBox = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), woodMaterial);

    // Enable shadow casting for pedestals
    largeBox.castShadow = true;
    mediumBox.castShadow = true;
    smallBox.castShadow = true;

    // Add pedestals to scene
    scene.add(largeBox);
    scene.add(mediumBox);
    scene.add(smallBox);


    // Create vitrines
    let largeBoxVitrine = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), glassMaterial);
    let mediumBoxVitrine = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), glassMaterial);
    let smallBoxVitrine = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), glassMaterial);

    // Add vitrines to scene
    scene.add(largeBoxVitrine);
    scene.add(mediumBoxVitrine);
    scene.add(smallBoxVitrine);


    // Add lighting
    const light = new THREE.DirectionalLight(0xF4F4F2, 2);
    light.position.set(30, 150, 60);
    // Enable shadow casting for light
    light.castShadow = true;
    // Adjust shadow camera frustum for better shadow quality
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 200;
    light.shadow.camera.left = -200;
    light.shadow.camera.right = 200;
    light.shadow.camera.top = 200;
    light.shadow.camera.bottom = -200;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    // Add ambient light to soften shadows
    const ambientLight = new THREE.AmbientLight(0xF4F4F2, 3);
    scene.add(ambientLight);

    // Add a ground plane to receive shadows
    const groundGeometry = new THREE.PlaneGeometry(200, 200);
    const groundMaterial = new THREE.MeshStandardMaterial({ color: 0xeeeeee });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.01; // Slightly below the pedestals
    ground.receiveShadow = true;
    scene.add(ground);


    // Position camera
    camera.position.set(-51, 40, 73); // looks nice for this scene
    controls.update();

    // Render loop
    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    }
    animate();

    // Handle window resizing
    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize() {
        renderContainer = document.getElementById('render-container').getBoundingClientRect();
        camera.aspect = renderContainer.width / renderContainer.width;
        camera.updateProjectionMatrix();
        renderer.setSize(renderContainer.width, renderContainer.width);
    }

    function pedestalBuilder() {
        let largeBoxWidth = Number(document.getElementById('width_input').value);
        let largeBoxHeight = Number(document.getElementById('height_input').value);
        let largeBoxDepth = Number(document.getElementById('depth_input').value);

        let mediumBoxWidth = largeBoxWidth - 2;
        let mediumBoxHeight = largeBoxHeight - 2;
        let mediumBoxDepth = largeBoxDepth - 2;

        let smallBoxWidth = largeBoxWidth - 4;
        let smallBoxHeight = largeBoxHeight - 4;
        let smallBoxDepth = largeBoxDepth - 4;

        
        let viewWidth = (largeBoxWidth + mediumBoxWidth + smallBoxWidth) / 3;

        // scale the pedestals
        largeBox.scale.set(largeBoxWidth, largeBoxHeight, largeBoxDepth);
        mediumBox.scale.set(mediumBoxWidth, mediumBoxHeight, mediumBoxDepth);
        smallBox.scale.set(smallBoxWidth, smallBoxHeight, smallBoxDepth);
        // reposition the pedestals
        largeBox.position.set(-viewWidth - 4, largeBoxHeight / 2, 0);
        mediumBox.position.set(0, mediumBoxHeight / 2, -1);
        smallBox.position.set(viewWidth + 2, smallBoxHeight / 2, -2);

        let include_vitrines = document.getElementById('include_vitrines').value;

        if (include_vitrines === 'no') {
            largeBoxVitrine.scale.set(0, 0, 0);
            mediumBoxVitrine.scale.set(0, 0, 0);
            smallBoxVitrine.scale.set(0, 0, 0);
        } else {
            let largeBoxVitrineHeight = Number(document.getElementById('vitrine_1_height_input').value);
            let mediumBoxVitrineHeight = Number(document.getElementById('vitrine_2_height_input').value);
            let smallBoxVitrineHeight = Number(document.getElementById('vitrine_3_height_input').value);

            largeBoxVitrine.scale.set(largeBoxWidth - 1, largeBoxVitrineHeight, largeBoxDepth - 1);
            mediumBoxVitrine.scale.set(mediumBoxWidth - 1, mediumBoxVitrineHeight, mediumBoxDepth - 1);
            smallBoxVitrine.scale.set(smallBoxWidth - 1, smallBoxVitrineHeight, smallBoxDepth - 1);

            largeBoxVitrine.position.set(-viewWidth - 4, largeBoxHeight + largeBoxVitrineHeight / 2, 0);
            mediumBoxVitrine.position.set(0, mediumBoxHeight + mediumBoxVitrineHeight / 2, -1);
            smallBoxVitrine.position.set(viewWidth + 2, smallBoxHeight + smallBoxVitrineHeight / 2, -2);
        }
    }
    
    pedestalBuilder();

    document.querySelector('#nesting-pedestal-builder').addEventListener('change', pedestalBuilder);
}