export function lineItemToAnalyticsProduct(lineItem) {
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#product-data
  return {
    id: lineItem.productID,
    name: lineItem.title,
    price: lineItem.price,
    quantity: lineItem.quantity,
  };
}

export function addToCart(lineItem) {
  const product = lineItemToAnalyticsProduct(lineItem);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "USD",
      add: {
        products: [product],
      },
    },
  });
}
