import { doc } from 'prettier';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { add } from 'three/examples/jsm/nodes/Nodes.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

export default function render(element) {
    // Get size of container
    let renderContainer = document.getElementById('render-container').getBoundingClientRect();

    // Set up scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, renderContainer.width / renderContainer.width, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(renderContainer.width, renderContainer.width); // Square aspect ratio based on width
    renderer.setClearColor(0x1f1e1c, 0);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Choose a tone mapping method
    renderer.toneMappingExposure = .9; // Adjust exposure
    renderer.physicallyCorrectLights = true; // Enable physical lighting
    // Enable shadow rendering
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: for softer shadows

    element.appendChild(renderer.domElement);

    const canvas = renderer.domElement;

    // Add Orbit Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Set the point around which the camera orbits
    const target = new THREE.Vector3(0, 24, 0); // Example target
    controls.target.copy(target);
    controls.update();

    // Prevent camera from going below ground
    const degrees = 80;
    const radians = degrees * Math.PI / 180;
    controls.maxPolarAngle = radians;

    // Add HDRI environment map
    const hdrEquirect = new RGBELoader().load(
        "/assets/img/threejs/hdr/rostock_laage_airport_1k.hdr",
        () => {
            hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
        }
    );

    // Create wood-like material
    const woodTexture = new THREE.TextureLoader().load('/assets/img/threejs/materials/cherry.jpg');
    const woodMaterial = new THREE.MeshPhysicalMaterial({
        map: woodTexture,
        clearcoat: 1,
    });
    woodTexture.colorSpace = THREE.SRGBColorSpace;

    const paintMaterial = new THREE.MeshStandardMaterial({ color: '#606E7B' });


    // Create glass material
    const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xa8ccd7,
        metalness: 0,
        roughness: 0.1,
        transmission: .9, // transparency
        opacity: 0.3,
        transparent: true,
        reflectivity: 0.9,
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        envMap: hdrEquirect,
    });



    // Create table
    const tableTop = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    const tableLeg1 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    const tableLeg2 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);

    // Create wood accent
    const woodApron1 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), woodMaterial);
    const woodApron2 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), woodMaterial);

    // Enable shadow casting for table
    tableTop.castShadow = true;
    tableLeg1.castShadow = true;
    tableLeg2.castShadow = true;
    woodApron1.castShadow = true;
    woodApron2.castShadow = true;

    // Add table to scene
    scene.add(tableTop);
    scene.add(tableLeg1);
    scene.add(tableLeg2);
    scene.add(woodApron1);
    scene.add(woodApron2);


    // Create vitrine and base
    let vitrine = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), glassMaterial);
    let vitrineBase = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);
    let vitrineBaseInterior = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), paintMaterial);

    // Add vitrine to scene
    scene.add(vitrine);
    scene.add(vitrineBase);
    scene.add(vitrineBaseInterior);


    // Add lighting
    const light = new THREE.DirectionalLight(0xF4F4F2, 2);
    light.position.set(30, 150, 60);
    // Enable shadow casting for light
    light.castShadow = true;
    // Adjust shadow camera frustum for better shadow quality
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 200;
    light.shadow.camera.left = -200;
    light.shadow.camera.right = 200;
    light.shadow.camera.top = 200;
    light.shadow.camera.bottom = -200;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    // Add ambient light to soften shadows
    const ambientLight = new THREE.AmbientLight(0xF4F4F2, 3);
    scene.add(ambientLight);

    // Add a ground plane to receive shadows
    const groundGeometry = new THREE.PlaneGeometry(200, 200);
    const groundMaterial = new THREE.MeshStandardMaterial({ color: 0xeeeeee });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.01; // Slightly below the pedestals
    ground.receiveShadow = true;
    scene.add(ground);


    // Position camera
    camera.position.set(-51, 40, 73); // looks nice for this scene
    controls.update();

    // Render loop
    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    }
    animate();

    // Handle window resizing
    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize() {
        renderContainer = document.getElementById('render-container').getBoundingClientRect();
        camera.aspect = renderContainer.width / renderContainer.width;
        camera.updateProjectionMatrix();
        renderer.setSize(renderContainer.width, renderContainer.width);
    }

    function tableBuilder() {
        let tableWidth = Number(document.getElementById('width_input').value);
        let tableHeight = Number(document.getElementById('height_input').value);
        let tableDepth = Number(document.getElementById('depth_input').value);
        let thickness = 1.5; // two pieces of 3/4" MDF

        // scale the table
        tableTop.scale.set(tableWidth, thickness, tableDepth);
        tableLeg1.scale.set(thickness, tableHeight - thickness, tableDepth);
        tableLeg2.scale.set(thickness, tableHeight - thickness, tableDepth);

        // scale the wood apron
        let apronWidth = tableWidth - 2 * thickness;
        let apronHeight = 6; // same height as Hentzen
        let apronDepth = .75;

        // if the table width is less than 24", make the apron height 9" less than (tableHheight - thickness)
        if (tableWidth < 24) {
            apronHeight = tableHeight - thickness - 9;
        }
        woodApron1.scale.set(apronWidth, apronHeight, apronDepth);
        woodApron2.scale.set(apronWidth, apronHeight, apronDepth);

        // reposition the components
        tableTop.position.set(0, tableHeight - thickness / 2, 0);
        tableLeg1.position.set(tableWidth / 2 - thickness / 2, tableHeight / 2, 0);
        tableLeg2.position.set(-tableWidth / 2 + thickness / 2, tableHeight / 2, 0);
        woodApron1.position.set(0, tableHeight - thickness - apronHeight / 2, tableDepth / 2 - apronDepth / 2 - 2);
        woodApron2.position.set(0, tableHeight - thickness - apronHeight / 2, -tableDepth / 2 + apronDepth / 2 + 2);

        let include_vitrines = document.getElementById('include_vitrines').value;

        if (include_vitrines === 'no') {
            vitrine.scale.set(0, 0, 0);
            vitrineBase.scale.set(0, 0, 0);
            vitrineBaseInterior.scale.set(0, 0, 0);
        } else {
            let vitrineHeight = Number(document.getElementById('vitrine_height_input').value);
            let vitrineWidth = Number(document.getElementById('vitrine_width_input').value);
            let vitrineDepth = Number(document.getElementById('vitrine_depth_input').value);

            vitrine.scale.set(vitrineWidth, vitrineHeight, vitrineDepth);
            vitrine.position.set(0, tableHeight + vitrineHeight / 2 + .25, 0);

            vitrineBase.scale.set(vitrineWidth, .25, vitrineDepth);
            vitrineBase.position.set(0, tableHeight + .125, 0);
            vitrineBaseInterior.scale.set(vitrineWidth - .25, .75, vitrineDepth - .25);
            vitrineBaseInterior.position.set(0, tableHeight + .25 + .75 / 2, 0);
        }
    }

    tableBuilder();

    document.querySelector('#table-case-builder').addEventListener('change', tableBuilder);
}