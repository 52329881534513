import React from "react";

export default class FormEmpty extends React.Component {
  render() {
    return (
      <div>
        <div className="rounded-md bg-blue-200 border border-blue-300 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-blue-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                Your quote doesn't contain any products yet.
              </p>
            </div>
          </div>
        </div>

        <p className="mt-2 text-sm">
          Click the <span className="italic font-semibold">Add to Quote</span>{" "}
          buttons from any product page to start building!
        </p>
        <p className="mt-4 text-sm">The buttons look like this:</p>
        <p className="mt-2 mb-16">
          <button data-quote="matfield_modular_reader_rail_45" className="btn">
            Add to Quote
          </button>
        </p>
      </div>
    );
  }
}
