{
  "waymaker_24x36": {
    "title": "Waymaker 24x36",
    "img": "/assets/img/products/waymaker/upland-waymaker-outdoor-sign-blackberry.jpg",
    "price": 1680,
    "shipping": 440,
    "addons": {
      "graphic_design": {
        "title": "8 hrs graphic design",
        "price": 600
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 720
      }
    }
  },
  "waymaker_10x15": {
    "title": "Waymaker 10x15",
    "img": "/assets/img/products/waymaker/upland-waymaker-1209-outdoor-sign-base.jpg",
    "price": 690,
    "shipping": 230,
    "addons": {
      "graphic_design": {
        "title": "4 hrs graphic design",
        "price": 300
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 300
      }
    }
  },
  "waymaker_18x48": {
    "title": "Waymaker 18x48",
    "img": "/assets/img/products/waymaker/upland-waymaker-1848-outdoor-sign-base.jpg",
    "price": 1440,
    "shipping": 380,
    "addons": {
      "graphic_design": {
        "title": "6 hrs graphic design",
        "price": 450
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 720
      }
    }
  },
  "matfield_modular_reader_rail_60": {
    "title": "Matfield Modular Reader Rail - 60\"",
    "img": "/assets/img/products/matfield/Upland-modular-reader-rail-60-in.jpg",
    "price": 995,
    "shipping": 220,
    "addons": {
      "graphic_design": {
        "title": "10 hrs graphic design",
        "price": 750
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 250
      },
      "flipbook_3_page": {
        "title": "3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "matfield_modular_reader_rail_45": {
    "title": "Matfield Modular Reader Rail - 45\"",
    "img": "/assets/img/products/matfield/Upland-modular-reader-rail-45-in.jpg",
    "price": 895,
    "shipping": 200,
    "addons": {
      "graphic_design": {
        "title": "10 hrs graphic design",
        "price": 750
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 225
      },
      "flipbook_3_page": {
        "title": "add 3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "matfield_flat_pack_reader_rail": {
    "title": "Matfield Flat Pack Reader Rail",
    "img": "/flat-pack/reader-rail/img/flat-pack-reader-rail-front-with-graphic.jpg",
    "price": 825,
    "shipping": 200,
    "addons": {
      "graphic_design": {
        "title": "10 hrs graphic design",
        "price": 750
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 225
      },
      "flipbook_3_page": {
        "title": "3 panel / 6 side D-ring flipbook",
        "price": 900
      },
      "flipbook_sheet_protector": {
        "title": "D-rings for plastic sheet protectors",
        "price": 375
      },
      "wheeled_dolly": {
        "title": "wheeled dolly",
        "price": 400
      }
    }
  },
  "matfield_flat_pack_wall_rail": {
    "title": "Matfield Flat Pack Wall Rail",
    "img": "/flat-pack/wall-rail/img/flat-pack-wall-rail-render-shadow.jpg",
    "price": 700,
    "shipping": 150,
    "addons": {
      "graphic_design": {
        "title": "8 hrs graphic design",
        "price": 600
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 200
      },
      "flipbook_3_page": {
        "title": "3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "matfield_pedestal_rail_36": {
    "title": "Matfield Pedestal Rail 36\"",
    "img": "/assets/img/products/matfield/pedestal-rail/Upland-Pedestal-Rail-MkII-36.jpg",
    "price": 2775,
    "shipping": 400,
    "addons": {
      "graphic_design": {
        "title": "10 hrs graphic design",
        "price": 750
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 200
      },
      "flipbook_3_page": {
        "title": "add 3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "matfield_pedestal_rail_45": {
    "title": "Matfield Pedestal Rail 45\"",
    "img": "/assets/img/products/matfield/pedestal-rail/Upland-Pedestal-Rail-MkII-45.jpg",
    "price": 2975,
    "shipping": 400,
    "addons": {
      "graphic_design": {
        "title": "10 hrs graphic design",
        "price": 750
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 225
      },
      "flipbook_3_page": {
        "title": "add 3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "matfield_pedestal_rail_60": {
    "title": "Matfield Pedestal Rail 60\"",
    "img": "/assets/img/products/matfield/pedestal-rail/Upland-Pedestal-Rail-MkII-60.jpg",
    "price": 2625,
    "shipping": 450,
    "addons": {
      "graphic_design": {
        "title": "10 hrs graphic design",
        "price": 750
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 250
      },
      "flipbook_3_page": {
        "title": "add 3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "cuesta_adjacent_two_window": {
    "title": "Cuesta Adjacent Two Window Case",
    "img": "/assets/img/products/cuesta/rectangular-corner-case.jpg",
    "price": 10975,
    "delivery": true
  },
  "cuesta_opposite_two_window": {
    "title": "Cuesta Opposite Two Window Case",
    "img": "/assets/img/products/cuesta/cuesta-two-window-case.jpg",
    "price": 10975,
    "delivery": true
  },
  "cuesta_three_window": {
    "title": "Cuesta Three Window Case",
    "img": "/assets/img/products/cuesta/cuesta-three-window-case.jpg",
    "price": 10975,
    "delivery": true
  },
  "cuesta_triangular": {
    "title": "Cuesta Triangular Case",
    "img": "/assets/img/products/cuesta/triangular-wall-case.jpg",
    "price": 11975,
    "delivery": true
  },
  "cuesta_double_sided": {
    "title": "Cuesta Double-Sided Case",
    "img": "/assets/img/products/cuesta/rectangular-freestanding-case-shelving.jpg",
    "price": 8500,
    "delivery": true
  },
  "quivira_3060": {
    "title": "Quivira 3060 Table Case",
    "img": "/assets/img/products/quivira/3060-table-case/Upland-Quivira-3060-Table-Case-Open.jpg",
    "price": 5370,
    "shipping": 475
  },
  "quivira_2438": {
    "title": "Quivira 2438 Table Case",
    "img": "/assets/img/products/quivira/2438-table-case/Upland-Quivira-2438-Table-Case-Open.jpg",
    "price": 4050,
    "shipping": 800
  },
  "quivira_pedestal": {
    "title": "Quivira Pedestal",
    "img": "/assets/img/products/quivira/quivira-case_16x16x36_charcoal.jpg",
    "price": 1350,
    "shipping": 360
  },
  "quivira_bench": {
    "title": "Quivira Museum Bench",
    "img": "/assets/img/products/quivira/1771-museum-bench/quivira-bench.jpg",
    "price": 1170,
    "shipping": 475
  },
  "storycart": {
    "title": "StoryCart",
    "img": "/assets/img/products/storycarts/502-001_d.jpg",
    "price": 4975,
    "shipping": 750,
    "addons": {
      "graphic_design": {
        "title": "24 hours graphic design",
        "price": 1800
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 650
      }
    }
  },
  "mobile_research_station": {
    "title": "Mobile Research Station",
    "img": "/assets/img/products/mobile-research-station/Mobile_Research_Station_-_dark_-_front.jpg",
    "price": 7575,
    "shipping": 890,
    "addons": {
      "graphic_design": {
        "title": "6 hrs graphic design",
        "price": 450
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 250
      }
    }
  },
  "bison_crate": {
    "title": "Bison Crate",
    "img": "/assets/img/products/bison/Upland-Bison-Crates-503-001-thumb.jpg",
    "price": 4050,
    "shipping": 650,
    "addons": {
      "graphic_design": {
        "title": "30 hrs graphic design",
        "price": 2250
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 700
      },
      "21.5_tabletop_touchscreen": {
        "title": "19.5\" tabletop touchscreen",
        "price": 2370
      },
      "touchscreen_programming": {
        "title": "40 hrs touchscreen programming",
        "price": 3000
      }
    }
  },
  "chase_crate_mkiv": {
    "title": "Chase Crate MkIV",
    "img": "/assets/img/products/chase/2525-chase-crate_discover-exoplanets.jpg",
    "price": 3475,
    "shipping": 650,
    "addons": {
      "graphic_design": {
        "title": "20 hrs graphic design",
        "price": 1500
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 700
      },
      "21.5_tabletop_touchscreen": {
        "title": "21.5\" tabletop touchscreen",
        "price": 3500
      },
      "touchscreen_programming": {
        "title": "40 hrs touchscreen programming",
        "price": 3000
      },
      "tabletop_case_20x12x12": {
        "title": "add 20\"x12\"x12\" tabletop vitrine case",
        "price": 850
      }
    }
  },
  "hudson": {
    "title": "Hudson Panel System",
    "img": "/assets/img/products/hudson/hudson-framework_b.jpg",
    "price": 4560,
    "shipping": 890,
    "addons": {
      "graphic_design": {
        "title": "20 hrs graphic design",
        "price": 1500
      },
      "graphics_production": {
        "title": "graphics production",
        "price": 750
      },
      "wide_width": {
        "title": "upgrade to wide unit",
        "price": 480
      },
      "flipbook_3_page": {
        "title": "add 3 panel / 6 side D-ring flipbook",
        "price": 900
      }
    }
  },
  "nesting_pedestals_wide_finished": {
    "title": "Finished Wide Nesting Pedestal Set",
    "img": "/assets/img/products/pedestals/nesting/Upland-Nesting-Pedestals-604-004.jpg",
    "price": 3975,
    "shipping": 650
  },
  "nesting_pedestals_wide_unfinished": {
    "title": "Unfinished Wide Nesting Pedestal Set",
    "img": "/assets/img/products/pedestals/nesting/Upland-unfinished-nesting-pedestal-set-604-003.jpg",
    "price": 3575,
    "shipping": 650
  },
  "nesting_pedestals_tall_finished": {
    "title": "Finished Tall Nesting Pedestal Set",
    "img": "/assets/img/products/pedestals/nesting/Upland-tall-nesting-pedestals-604-002.jpg",
    "price": 3325,
    "shipping": 650
  },
  "nesting_pedestals_tall_unfinished": {
    "title": "Unfinished Tall Nesting Pedestal Set",
    "img": "/assets/img/products/pedestals/nesting/Upland-unfinished-tall-nesting-pedestal-set-604-001.jpg",
    "price": 2925,
    "shipping": 650
  },
  "hardwood_sign_base_baltic_unfinished_18": {
    "title": "Hardwood Sign Base Baltic Birch Unfinished 18\"",
    "img": "/assets/img/products/sign-bases/18_Inch_Sign_Base_Pair.jpg",
    "price": 445,
    "shipping": 160
  },
  "hardwood_sign_base_baltic_unfinished_24": {
    "title": "Hardwood Sign Base Baltic Birch Unfinished 24\"",
    "img": "/assets/img/products/sign-bases/24_Inch_Sign_Base_Pair.jpg",
    "price": 465,
    "shipping": 160
  },
  "hardwood_sign_base_baltic_unfinished_36": {
    "title": "Hardwood Sign Base Baltic Birch Unfinished 36\"",
    "img": "/assets/img/products/sign-bases/36_Inch_Sign_Base_Pair.jpg",
    "price": 485,
    "shipping": 160
  },
  "hardwood_sign_base_baltic_finished_18": {
    "title": "Hardwood Sign Base Baltic Birch Finished 18\"",
    "img": "/assets/img/products/sign-bases/18_Inch_Sign_Base_Pair.jpg",
    "price": 545,
    "shipping": 160
  },
  "hardwood_sign_base_baltic_finished_24": {
    "title": "Hardwood Sign Base Baltic Birch Finished 24\"",
    "img": "/assets/img/products/sign-bases/24_Inch_Sign_Base_Pair.jpg",
    "price": 565,
    "shipping": 160
  },
  "hardwood_sign_base_baltic_finished_36": {
    "title": "Hardwood Sign Base Baltic Birch Finished 36\"",
    "img": "/assets/img/products/sign-bases/36_Inch_Sign_Base_Pair.jpg",
    "price": 585,
    "shipping": 160
  },
  "hardwood_sign_base_cherry_18": {
    "title": "Hardwood Sign Base Cherry (tung oil) 18\"",
    "img": "/assets/img/products/sign-bases/18_Inch_Sign_Base_Pair.jpg",
    "price": 550,
    "shipping": 160
  },
  "hardwood_sign_base_cherry_24": {
    "title": "Hardwood Sign Base Cherry (tung oil) 24\"",
    "img": "/assets/img/products/sign-bases/24_Inch_Sign_Base_Pair.jpg",
    "price": 575,
    "shipping": 160
  },
  "hardwood_sign_base_cherry_36": {
    "title": "Hardwood Sign Base Cherry (tung oil) 36\"",
    "img": "/assets/img/products/sign-bases/36_Inch_Sign_Base_Pair.jpg",
    "price": 600,
    "shipping": 160
  },
  "lincoln_showcase": {
    "title": "Lincoln Showcase",
    "img": "/lincoln/showcase/img/upland-lincoln-showcase-single.jpg",
    "price": 12500,
    "delivery": true
  },
  "lincoln_wallcase": {
    "title": "Lincoln Wallcase",
    "img": "/lincoln/wallcase/img/lincoln-wallcase-quintuple.jpg",
    "price": 11500,
    "delivery": true
  }
}
