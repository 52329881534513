import React from "react";

export default class FormContactInformation extends React.Component {
  render() {
    const { contact_fields, changeHandler } = this.props;

    return (
      <div>
        <h3>Contact Information</h3>
        <p className="text-sm italic text-gray-600">All fields required</p>
        <div className="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-2 print:grid-cols-2 print:text-xs">
          <div className="mt-2">
            {/* <p className="mb-3 text-center">Upland quote request #1001</p> */}
            <label
              htmlFor="organization_name"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              Organization name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="organization_name"
                id="organization_name"
                autoComplete="given-name"
                value={contact_fields.organization_name}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="contact_name"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              Contact name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="contact_name"
                id="contact_name"
                autoComplete="given-name"
                value={contact_fields.contact_name}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              Phone number
            </label>
            <div className="mt-1">
              <input
                id="phone"
                name="phone"
                type="tel"
                autoComplete="tel"
                value={contact_fields.phone}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={contact_fields.email}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-4 gap-y-2 gap-x-4 sm:grid-cols-6 print:grids-cols-6">
          <div className="col-span-2 sm:col-span-6 print:col-span-6">
            <label
              htmlFor="street_address"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              Street address
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="street_address"
                id="street_address"
                autoComplete="street-address"
                value={contact_fields.street_address}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>

          <div className="col-span-2 sm:col-span-2 print:col-span-2">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              City
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="city"
                id="city"
                value={contact_fields.city}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>

          <div className="sm:col-span-2 print:col-span-2">
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              State
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="state"
                id="state"
                value={contact_fields.state}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>

          <div className="sm:col-span-2 print:col-span-2">
            <label
              htmlFor="zip"
              className="block text-sm font-medium text-gray-700 print:text-xs"
            >
              ZIP
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="zip"
                id="zip"
                autoComplete="postal-code"
                value={contact_fields.zip}
                onChange={changeHandler}
                required={true}
                className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm print:text-xs"
              />
            </div>
          </div>
        </div>
        <label className="flex items-center mt-6 space-x-3">
          <input
            type="checkbox"
            name="can_accept_freight"
            checked={contact_fields.can_accept_freight}
            onChange={changeHandler}
            className="w-6 h-6 mb-1 border border-gray-300 rounded-md appearance-none form-tick checked:bg-blue-600 checked:border-transparent focus:outline-none print:w-4 print:h-4 print:text-xs"
          />
          <span className="text-sm print:text-xs">
            this address can accept freight shipments
          </span>
        </label>
        <label className="flex items-center mt-2 space-x-3">
          <input
            type="checkbox"
            name="tax_exempt"
            checked={contact_fields.tax_exempt}
            onChange={changeHandler}
            className="w-6 h-6 mb-1 border border-gray-300 rounded-md appearance-none form-tick checked:bg-blue-600 checked:border-transparent focus:outline-none print:w-4 print:h-4 print:text-xs"
          />
          <span className="text-sm print:text-xs">
            my organization is sales tax exempt
          </span>
        </label>
      </div>
    );
  }
}
